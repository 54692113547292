import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges, ViewChild
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, Subscription} from "rxjs";
import { ModulesDataModel } from 'src/app/model/device-list.model';
import { ModuleType } from 'src/app/model/module-list.model';
import { DeviceListService } from 'src/app/services/device-list.service';
import { LoginService } from 'src/app/services/login.service';
import {
  LocationSelectorFilterService
} from "../../services/location-selector-filter.service";

@Component({
             selector: 'app-location-selector',
             templateUrl: './location-selector.component.html',
             styleUrls: ['./location-selector.component.scss']
           })
export class LocationSelectorComponent implements OnChanges {

  @Input('deviceId') inputDeviceId: string = '';
  modules: ModulesDataModel | undefined;
  selectedCountry: string = '';
  editableState:boolean=false;
  searchByLocation = false;
  customerList: Set<string> = new Set<string>();
  searchCustomerList: Set<string> = new Set<string>();
  cityList: string[] = [];
  deviceIdList: string[] = [];
  allDeviceIdsList: string[] = [];
  countryList: string[] = [];
  searchCountryList: string[] = [];
  selectedCountryIndex = -1;
  selectedSearchedCountryIndex = -1;
  selectedCityIndices = new Set<number>();
  selectedCustomer: string = '';
  selectedDeviceId: string = '';
  selectedCityIndex: number=-1;
  searchedCityList:string[]=[];
  selectedCityList:string[]=[];
  cityControl:FormControl<string[]> = new FormControl();
  citySearchControl: FormControl<string>= new FormControl();
  countrySearchControl: FormControl<string>= new FormControl();
  customerSearchControl : FormControl<string>= new FormControl();
  private subscriptions = new Subscription();

  @Input() selectedModuleType: ModuleType | null = null;
  @Output() setDeviceId: EventEmitter<string> = new EventEmitter<string>();
  @Output() initializationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('countrySearchInputControl') countrySearchInputControl!: ElementRef<HTMLInputElement>;
  @ViewChild('citySearchInputControl') citySearchInputControl!: ElementRef<HTMLInputElement>;
  @ViewChild('customerInputSearchControl') customerInputSearchControl!: ElementRef<HTMLInputElement>;

  constructor(
    public loginService: LoginService,
    public deviceListService: DeviceListService,
    public eRef: ElementRef,
    public locationSelectorSearchService : LocationSelectorFilterService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.citySearchControl.valueChanges.subscribe(() => this.onCitySearchChange())
    );
    this.subscriptions.add(
      this.countrySearchControl.valueChanges.subscribe(() => this.onCountrySearchChange())
    );
    this.subscriptions.add(
      this.customerSearchControl.valueChanges.subscribe(() => this.onCustomerSearchChange())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    try{
      if (changes['selectedModuleType']?.currentValue != null) {
        this.modules = await this.deviceListService.getData();
        await this.initializeData();
        this.initializationComplete.emit(true);
      }
    }
    catch(error){
      console.log('error in location selector initialization:', error);
    }
  }

  async initializeData() {
    //serial no. list
    this.allDeviceIdsList = this.modules?.data.moduleInformation?.countries.flatMap(country => country.cities).flatMap(city => city.customers).flatMap(customer => customer.serialNos) ?? [];
    //country list
    this.countryList = this.modules?.data.moduleInformation?.countries.map(c => c.name) ?? [];
    this.searchCountryList = this.modules?.data.moduleInformation?.countries.map(c => c.name) ?? [];;
    this.resetToDefault();
  }

  handleSearchChange(control: FormControl, filterFunction: (searchText: string) => void, resetFunction: () => void) {
    const searchTerm = control.value?.trim().toLowerCase() || '';

    if (!searchTerm) {
      resetFunction();
      return;
    }

    control.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(searchText => filterFunction(searchText.toLowerCase()));
  }

  onCitySearchChange() {
    this.handleSearchChange(
      this.citySearchControl,
      (searchText) => this.searchedCityList = this.locationSelectorSearchService.filterCities(this.cityList, this.selectedCityList, searchText),
      () => this.searchedCityList = [...this.cityList]
    );
  }

  onCountrySearchChange() {
    this.handleSearchChange(
      this.countrySearchControl,
      (searchText) => this.searchCountryList = this.locationSelectorSearchService.filterCountries(this.countryList, searchText),
      () => this.searchCountryList = [...this.countryList]
    );
  }

  onCustomerSearchChange() {
    this.handleSearchChange(
      this.customerSearchControl,
      (searchText) => this.searchCustomerList = this.locationSelectorSearchService.filterCustomers(this.customerList, searchText),
      () => this.searchCustomerList = new Set<string>(this.customerList)
    );
  }

  onCountryChanged(country:string) {
    this.selectedCountry = country;
    this.editableState = false;
    const countryIndex = this.countryList.indexOf(country);
    if (countryIndex === this.selectedCountryIndex) return;
    this.cityList = this.modules?.data.moduleInformation?.countries[countryIndex].cities.map(c => c.name) ?? [];
    this.selectedCountryIndex = countryIndex;
    this.selectedSearchedCountryIndex = countryIndex;
    this.selectedCityIndices.clear();
    this.customerList.clear();
    this.deviceIdList = [];
    //need to reset city, customer and serialNo
    this.resetSearchByLocFields('onCountryChanged');
    this.searchedCityList=this.cityList;
    this.selectedCityList = this.cityControl.value || [];
    this.cityControl.valueChanges.subscribe(selectedCities => {
      this.selectedCityList = selectedCities;
    });
  }

  onCityChanged(event: any, index: number, city:string) {
    let selected: boolean = event.target.active;
    if (selected && (!this.selectedCityIndices.has(this.cityList.indexOf(city)))) {
      this.selectedCityList.includes(city) || this.selectedCityList.push(city);
      this.selectedCityIndex=this.cityList.indexOf(city);
      this.selectedCityIndices.add(this.selectedCityIndex);
    }
    else {
      this.selectedCityList = this.selectedCityList.filter(s=> s!==city);
      this.selectedCityIndex=this.cityList.indexOf(city);
      this.selectedCityIndices.delete(this.selectedCityIndex);
    }
    //update customer list. can contain duplicates.
    let countryData = this.modules?.data.moduleInformation?.countries[this.selectedCountryIndex];
    if (countryData) {
      this.customerList.clear();
      this.searchCustomerList.clear();
      this.deviceIdList = [];
      for (let i of this.selectedCityIndices) {
        let customerData = countryData.cities[i].customers.map(c => c.name) ?? [];
        for (let customer of customerData) {
          this.customerList.add(customer);
          this.searchCustomerList.add(customer);
        }
      }
      this.resetSearchByLocFields('onCityChanged');
    }
  }

  onCustomerChanged(customer: any) {
    if (this.selectedCustomer === customer) return;
    this.selectedCustomer = customer;
    this.deviceIdList = this.modules?.data.moduleInformation.countries[this.selectedCountryIndex]?.cities?.filter((val, i) => this.selectedCityIndices.has(i))
    .flatMap(c => c.customers)
                                                                                                   .filter((c) => c.name === this.selectedCustomer)
                                                                                                   .flatMap(c => c.serialNos) ?? [];
    //need to reset serial no.
    this.resetSearchByLocFields('onCustomerChanged');
  }

  resetSearchByLocFields(resetType: string) {
    switch (resetType) {
      case 'onCountryChanged':
        this.resetCitySelection();
        this.resetCustomerSelection();
        this.resetDeviceIdSelection();
        break;
      case 'onCityChanged':
        this.resetCustomerSelection();
        this.resetDeviceIdSelection();
        break;
      case 'onCustomerChanged':
        this.resetDeviceIdSelection();
        break;
    }
  }

  resetCountrySelection() {
    let customerEl = document.getElementById(this.searchByLocation ? 'Selector_Country_DropDown' : 'Selector_Country_Input');
    if ((customerEl as any).value !== '') {
      (customerEl as any).value = '';
    }
    this.selectedCountryIndex = -1;
    this.resetSearchByLocFields('onCountryChanged');
  }

  resetCitySelection() {
    let cityEl = document.getElementById(this.searchByLocation ? 'Selector_City_DropDown' : 'Selector_City_Input');
    (cityEl as any).value = '';
    (cityEl as any)._multiValue = [];
    if(this.selectedCountryIndex==-1)
    {
      this.cityList=[];
      this.searchedCityList=[];
      this.selectedCityList=[];
    }
    this.selectedCityIndices.clear();
    this.selectedCityIndex = -1;
  }

  resetCustomerSelection() {
    let customerEl = document.getElementById(this.searchByLocation ? 'Selector_Customer_DropDown' : 'Selector_Customer_Input');
    if ((customerEl as any).value !== '') {
      (customerEl as any).value = '';
    }
    if(this.selectedCityIndex == -1)
    {
      this.customerList.clear();
      this.searchCustomerList.clear();
    }
    this.deviceIdList=[];
    this.selectedCustomer = '';
  }

  resetDeviceIdSelection() {
    let el = document.getElementById(this.searchByLocation ? 'Selector_SerialNo_DropDown' : 'Selector_SerialNo_Search');
    if (el) {
      (el as any).value = '';
    }
    this.selectedDeviceId = '';
    this.setDeviceId.emit(this.selectedDeviceId);
  }

  onSerialNoChanged(deviceId: any) {
    if(this.selectedCountryIndex!== -1 && this.selectedCustomer !==''&& this.selectedCityIndices.size !==0){
      this.selectedDeviceId = deviceId.toUpperCase();
      let validDeviceId = this.deviceIdList.includes(this.selectedDeviceId)
      this.setDeviceId.emit(validDeviceId ? this.selectedDeviceId : '');
    }
  }

  setLocationDetails(event: any) {
    this.selectedDeviceId = this.locationSelectorSearchService.normalizeInput(event.target.value).toUpperCase();
    event.target.value = this.selectedDeviceId;
    let validDeviceId = this.allDeviceIdsList.includes(this.selectedDeviceId)
    let location = this.getLocationDetails(this.selectedDeviceId);
    let countryEl = document.getElementById('Selector_Country_Input');
    let cityEl = document.getElementById('Selector_City_Input');
    let customerEl = document.getElementById('Selector_Customer_Input');
    (countryEl as any).value = location?.country ?? '';
    (cityEl as any).value = location?.city ?? '';
    if (location?.city) {
      this.selectedCityList = [location.city];
    }
    (customerEl as any).value = location?.customer ?? '';
    this.setDeviceId.emit(validDeviceId ? this.selectedDeviceId : '');
  }

  getLocationDetails(deviceId: string): { country: string, countryIndex: number, city: string, cityIndex: number, customer: string, customerIndex: number, deviceId: string } | undefined {
    let countries = this.modules?.data.moduleInformation.countries ?? [];
    for (let [countryIndex, country] of countries.entries()) {
      for (let [cityIndex, city] of country.cities.entries()) {
        for (let [customerIndex, customer] of city.customers.entries()) {
          if (customer.serialNos.find((sNo) => sNo === deviceId)) {
            return {
              country: country.name,
              city: city.name,
              customer: customer.name,
              deviceId,
              countryIndex,
              cityIndex,
              customerIndex
            };
          }
        }
      }
    }
    return undefined;
  }

  onSearchByLocation(searchByLocation: boolean) {
    this.searchByLocation = searchByLocation;
    let details = this.getLocationDetails(this.selectedDeviceId);
    this.setDetails(details);

  }

  setDetails(details: { country: string; countryIndex: number; city: string; cityIndex: number; customer: string; customerIndex: number; deviceId: string; } | undefined) {
    if (this.searchByLocation) {
      this.handleSearchByLocation(details);
    }
    else if (!this.searchByLocation) {
      this.handleSearchByDeviceId(details);
    }
  }

  handleSearchByDeviceId(details: { country: string; countryIndex: number; city: string; cityIndex: number; customer: string; customerIndex: number; deviceId: string; } | undefined) {
    setTimeout(() => {
      let countryEl = document.getElementById('Selector_Country_Input');
      let cityEl = document.getElementById('Selector_City_Input');
      let customerEl = document.getElementById('Selector_Customer_Input');
      let deviceIdSearchEl = document.getElementById('Selector_SerialNo_Search');
      (countryEl as any).value = details?.country ?? '';
      (cityEl as any).value = details?.city ?? '';
      (customerEl as any).value = details?.customer ?? '';
      this.selectedDeviceId = details?.deviceId ?? '';
      (deviceIdSearchEl as any).value = this.selectedDeviceId;
      deviceIdSearchEl?.setAttribute('search-array', JSON.stringify(this.allDeviceIdsList));
      this.selectedCountryIndex = -1;
      this.selectedCityIndices.clear();
      this.selectedCustomer = '';
      this.customerList.clear();
      this.cityList = [];
    }, 0);
  }

  handleSearchByLocation(details: { country: string; countryIndex: number; city: string; cityIndex: number; customer: string; customerIndex: number; deviceId: string; } | undefined) {
    if (details) {
      this.selectedCountryIndex = details.countryIndex;
      this.selectedCityIndices.add(details.cityIndex);
      this.selectedCustomer = details.customer;
      this.selectedDeviceId = details.deviceId;
      this.cityList = this.modules?.data.moduleInformation?.countries[this.selectedCountryIndex].cities.map(c => c.name) ?? [];
      this.searchedCityList = this.cityList;
      this.customerList = new Set<string>(this.modules?.data.moduleInformation.countries[this.selectedCountryIndex]?.cities?.filter((c, i) => this.selectedCityIndices.has(i))?.flatMap(c => c.customers)?.map(c => c.name));
      this.searchCustomerList = this.customerList;
      this.deviceIdList = this.modules?.data.moduleInformation?.countries[this.selectedCountryIndex]?.cities?.filter((val, i) => this.selectedCityIndices.has(i))
      .flatMap(c => c.customers)
                                                                                                      .filter((c) => c.name === this.selectedCustomer)
                                                                                                      .flatMap(c => c.serialNos) ?? [];
    }
    else {
      this.selectedCountryIndex = -1;
      this.selectedCityIndices.clear();
      this.selectedCustomer = '';
      this.selectedDeviceId = '';
      this.cityList = [];
      this.searchedCityList=[];
      this.selectedCityList=[];
      this.customerList.clear();
      this.deviceIdList = [];
    }
    setTimeout(() => {
      let countryEl = document.getElementById('Selector_Country_DropDown');
      let cityEl = document.getElementById('Selector_City_DropDown');
      let customerEl = document.getElementById('Selector_Customer_DropDown');
      let deviceIdSearchEl = document.getElementById('Selector_SerialNo_DropDown');
      (countryEl as any).value = details?.country ?? '';
      (cityEl as any).value = details?.city ?? '';
      (customerEl as any).value = details?.customer ?? '';
      (deviceIdSearchEl as any).value = details?.deviceId ?? '';
    }, 0);
  }


  resetToDefault() {
    this.selectedDeviceId = this.inputDeviceId;
    let details = this.getLocationDetails(this.selectedDeviceId);
    this.setDetails(details);
  }

  onCountryFocusOut(event: any) {
    let country = event.target.value;
    if (event.relatedTarget ==null && this.selectedCountryIndex !== -1 && !this.searchCountryList.includes(country)) {
      event.target.value = this.searchCountryList[this.selectedCountryIndex];
    }
  }

  onCustomerFocusOut(event: any) {
    let customer = event.target.value;
    this.countrySearchControl.setValue('');
    if (event.relatedTarget ==null && this.selectedCustomer !== customer) {
      event.target.value = this.selectedCustomer;
    }
  }

  onDeviceIdFocusOut(event: any) {
    let deviceId = event.target.value;
    if (event.relatedTarget ==null && this.selectedDeviceId !== deviceId) {
      event.target.value = this.selectedDeviceId;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    this.editableState = false;
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.citySearchControl.setValue('');
      this.countrySearchControl.setValue('');
      this.customerSearchControl.setValue('');
    }
  }

  focusInput(){
    if (this.countrySearchInputControl) {
      this.countrySearchControl.setValue('');
      setTimeout(() => {
        this.countrySearchInputControl.nativeElement.focus();
      }, 0);
    }
    if (this.customerInputSearchControl) {
      setTimeout(() => this.customerInputSearchControl.nativeElement.focus(), 0);
    }
    if (this.citySearchInputControl) {
      setTimeout(() => this.citySearchInputControl.nativeElement.focus(), 0);
    }
  }

  serialValueEvent(event:any){
    const deviceIdValue = this.locationSelectorSearchService.normalizeInput(event.target.value).toUpperCase();
    let validDeviceId = this.deviceIdList.includes(deviceIdValue);
    if(this.searchByLocation && validDeviceId)
    {
      event.target.value = deviceIdValue;
      this.onSerialNoChanged(deviceIdValue)
    }
  }

  inputFocusIn(){
    this.editableState = true;
  }
}
