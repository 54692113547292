import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private msalService: MsalService, private router: Router) { }


  logout() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    localStorage.clear();
    sessionStorage.clear();
    if (activeAccount) {
      this.msalService.instance.logout();
    } else {
      this.msalService.instance.logout();
      console.log("No active account to log out.");
    }
  }

  getAccessToken() {
    return this.msalService.acquireTokenSilent({
      scopes: [environment.scope],
    })
  }

}
