import { Injectable } from '@angular/core';
import { Chart, ChartConfiguration, LegendItem } from 'chart.js/auto';
import { QcLJSelectedFilter } from 'src/app/model/qc-l-j-filter.model';
import moment from 'moment';
import { QcReference } from '../model/qc.communication.model';

@Injectable({
  providedIn: 'root',
})
export class QcSignalResponseService {
  selectedFilter: QcLJSelectedFilter = {
    serial: '',
    test: {
      name: '',
      longName: '',
    },
    units: '',
    qcLotLevel: new Set<string>(),
    reagentLot: '',
    calLot: '',
    latestDate: new Date(0),
    earliestDate: new Date(0),
    specimenType: '',
    dateRangeString: '',
    assay: '',
  };
  dataPointColors = [
    '#8F1AFF',
    '#009B00',
    '#006ADD',
    '#FFD200',
    '#EC6602',
    '#000000',
    '#707070',
  ];
  reagentLotList: Set<string> = new Set<string>();
  calLotList: Set<string> = new Set<string>();
  item: any = [];

  drawGraph(response: any, qcResultFlag: boolean): any {
    let qcRef = response.qcRef ?? {};
    this.selectedFilter = response.filter ?? {};
    this.calLotList = response.calLot ?? [];
    this.reagentLotList = response.reagentLot ?? [];
    this.item = response.item ?? [];

    let data: any[] = (response.data ?? [])
      .map((x: any) => {
        const resultDate = moment(x.time.split('Z')[0], true).toDate();
        return qcResultFlag
          ? {
            x: resultDate.getTime(),
            y: x.plotValue,
            tooltipLabel:
              resultDate.toLocaleString() +
              '\nValue = ' +
              x.value +
              ' ' +
              response?.item.unit +
              '\n' +
              (x.errFlgs == '' ? '' : 'Error Flag = ' + x.errFlgs),
            color: this.dataPointColors[0],
            reagentLot: x.rgtLot,
            calLot: x.calLot,
            selected: '',
          }
          : {
            x: resultDate.getTime(),
            y: x.signalRespValue,
            tooltipLabel:
              resultDate.toLocaleString() +
              '\nSignal Response Value = ' +
              x.signalRespValue +
              ' mAU' +
              '\n',
            color: this.dataPointColors[0],
            reagentLot: x.rgtLot,
            calLot: x.calLot,
            selected: '',
          };
      })
      .sort(
        (x: any, y: any) => new Date(x.x).getTime() - new Date(y.x).getTime()
      );

    this.assignColorsToDataPoints(data);

    const chartData: ChartConfiguration['data'] = {
      datasets: [
        {//Default Data points
          type: 'scatter',
          label: '',
          backgroundColor: function (dataPoint, _context) {
            return dataPoint.type === 'dataset'
              ? ''
              : (dataPoint.raw as any).color;
          },
          data: data,
          pointStyle: 'circle',
          pointRadius: 5,
          showLine: true,
          borderColor: function (dataPoint, _context) {
            return dataPoint.type === 'dataset'
              ? ''
              : (dataPoint.raw as any).color;
          },
          borderWidth: 1,
        },
        {//Excluded Data points 
          type: 'scatter',
          label: '',
          backgroundColor: function (_dataPoint, _context) {
            return 'rgba(0,0,0,0)';
          },
          data: [],
          pointStyle: 'circle',
          pointRadius: 5,
          showLine: false,
          borderColor: function (dataPoint, _context) {
            return dataPoint.type === 'dataset'
              ? ''
              : (dataPoint.raw as any).color;
          },
          borderWidth: 2,
        },
      ],
    };

    const options = this.getUpdatedOptions(data, qcRef, qcResultFlag);

    return { chartData: chartData, options: options };
  }

  private getScaleMinMaxValues(
    qcRef: QcReference,
    qcSignalValue: any,
    qcResultFlag: boolean
  ) {
    const interval = 2;
    let ymin = qcResultFlag
      ? this.getYMinValue(qcRef)
      : (Math.ceil(Math.min(...qcSignalValue))) - interval;
    let ymax = qcResultFlag
      ? this.getYMaxValue(qcRef)
      : (Math.floor(Math.max(...qcSignalValue))) + interval;
    const [selectedFrom, selectedTo] =
      this.selectedFilter?.dateRangeString?.split('-') ?? [];
    const xminDate = moment(
      new Date(selectedFrom),
      'YYYY-MM-DD',
      true
    ).toDate();
    const xMaxDate = moment(new Date(selectedTo), 'YYYY-MM-DD', true).toDate();
    xMaxDate.setDate(xMaxDate.getDate() + 1);
    return { xmax: xMaxDate, xmin: xminDate, ymin: ymin, ymax: ymax };
  }

  public assignColorsToDataPoints(
    data: {
      x: number;
      y: number;
      tooltipLabel: string;
      color: string;
      reagentLot: string;
      calLot: string;
      selected: string;
    }[]
  ): Map<string, number> {
    let typeColorMap = new Map<string, number>();
    if (this.selectedFilter.reagentLot === 'View All') {
      this.assignReagentColors(typeColorMap, data);
    } else if (this.selectedFilter.calLot === 'View All') {
      this.assignCalColors(typeColorMap, data);
    } else {
      for (let point of data) {
        point.color = this.dataPointColors[0];
      }
    }
    return typeColorMap;
  }

  private assignCalColors(
    typeColorMap: Map<string, number>,
    data: {
      x: number;
      y: number;
      tooltipLabel: string;
      color: string;
      reagentLot: string;
      calLot: string;
      selected: string;
    }[]
  ) {
    let i = 0;
    for (let cal of this.calLotList) {
      if (!typeColorMap.has(cal)) {
        typeColorMap.set(cal, i++);
      }
    }
    for (let point of data) {
      point.color = this.dataPointColors[typeColorMap.get(point.calLot) ?? 0];
      point.selected = point.calLot;
    }
  }

  private assignReagentColors(
    typeColorMap: Map<string, number>,
    data: {
      x: number;
      y: number;
      tooltipLabel: string;
      color: string;
      reagentLot: string;
      calLot: string;
      selected: string;
    }[]
  ) {
    let i = 0;
    for (let rgt of this.reagentLotList) {
      if (!typeColorMap.has(rgt)) {
        typeColorMap.set(rgt, i++);
      }
    }
    for (let point of data) {
      point.color =
        this.dataPointColors[typeColorMap.get(point.reagentLot) ?? 0];
      point.selected = point.reagentLot;
    }
  }

  private generateYAxisTickLabels(chart: Chart, qcResultFlag: boolean) {
    let legendItems: LegendItem[] = [];
    let data = chart.data.datasets[0].data;
    let map = new Map<string, string>();
    for (let dataPoint of data) {
      if ((dataPoint as any).selected !== '') {
        map.set((dataPoint as any).selected, (dataPoint as any).color);
      }
    }
    if (qcResultFlag) {
      legendItems.push({
        text: 'QC error flag  ',
        pointStyle: 'rectRounded',
        borderRadius: 10,
        fillStyle: 'transparent',
        strokeStyle: 'red',
      });
    }
    legendItems.push({
      text: this.getLegendTitle() ?? '',
      fillStyle: 'transparent',
      strokeStyle: 'white',
      pointStyle: 'dash',
    });
    for (let pair of map) {
      let item: LegendItem = {
        text: pair[0],
        fillStyle: pair[1],
      };
      legendItems.push(item);
    }
    return legendItems;
  }

  public getLegendTitle(): string | undefined {
    let legendTitle = '';
    if (this.selectedFilter.reagentLot === 'View All') {
      legendTitle = 'Reagent Lots';
    } else if (this.selectedFilter.calLot === 'View All') {
      legendTitle = 'Calibrator Lots';
    }
    return legendTitle;
  }

  public generateXAxisTicks(xminDate: Date, xMaxDate: Date) {
    let timeArray = [];
    const startDate = moment(xminDate, 'YYYY-MM-DD', true).toDate();
    while (startDate <= xMaxDate) {
      let tick = {
        value: startDate.getTime(),
        label: startDate.toLocaleDateString(),
      };
      timeArray.push(tick);
      startDate.setDate(startDate.getDate() + 1);
    }
    return timeArray;
  }

  private generateYAxisTicksLabels(
    value: string | number,
    qcRef: QcReference,
    ymax: number,
    ymin: number
  ) {
    let str = parseFloat((+value).toFixed(4)).toString();
   
    if (qcRef.sd === 0) {
      str = value === qcRef.mean ? 'Mean ' + str : '';
    } else {
      switch (value) {
        case ymax + qcRef.sd:
          str = 'Outliers';
          break;
        case ymin - qcRef.sd:
          str = 'Outliers';
          break;
        case ymax:
          str = '+6 SD  ' + str;
          break;
        case qcRef.sd3Up + (qcRef.sd * 2):
          str = '+5 SD  ' + str;
          break;
        case qcRef.sd3Up + qcRef.sd:
          str = '+4 SD  ' + str;
          break;
        case qcRef.sd3Up:
          str = '+3 SD  ' + str;
          break;
        case qcRef.sd2Up:
          str = '+2 SD  ' + str;
          break;
        case qcRef.sd1Up:
          str = '+1 SD  ' + str;
          break;
        case qcRef.mean:
          str = 'Mean  ' + str;
          break;
        case qcRef.sd1Low:
          str = '-1 SD  ' + str;
          break;
        case qcRef.sd2Low:
          str = '-2 SD  ' + str;
          break;
        case qcRef.sd3Low:
          str = '-3 SD  ' + str;
          break;
        case qcRef.sd3Low - qcRef.sd:
          str = '-4 SD  ' + str;
          break;
        case qcRef.sd3Low - (qcRef.sd * 2):
          str = '-5 SD  ' + str;
          break;
        case ymin:
          str = '-6 SD  ' + str;
          break;
        default:
          str = '';
          break;
      }
      //fixed value to 2 decimal places
    }
    return str;
  }

  private generateYAxisTicks(qcRef: QcReference, ymin: number, ymax: number) {
    let ticksValues;
    if (qcRef.sd === 0) {
      ticksValues = [
        {
          value: qcRef.mean,
          label: qcRef.mean.toString(),
        },
        {
          value: ymin,
          label: '',
        },
        {
          value: ymax,
          label: '',
        },
      ];
    } else {
      ticksValues = [
        {
          value: ymax + qcRef.sd,
          label: '',
        },
        {
          value: ymin - qcRef.sd,
          label: '',
        },
        {
          value: ymax,
          label: '',
        },
        {
          value: qcRef.sd3Up + qcRef.sd * 2,
          label: '',
        },
        {
          value: qcRef.sd3Up + qcRef.sd,
          label: '',
        },
        {
          value: qcRef.sd3Up,
          label: qcRef.sd3Up.toString(),
        },
        {
          value: qcRef.sd2Up,
          label: qcRef.sd2Up.toString(),
        },
        {
          value: qcRef.sd1Up,
          label: qcRef.sd1Up.toString(),
        },
        {
          value: qcRef.mean,
          label: qcRef.mean.toString(),
        },
        {
          value: qcRef.sd3Low,
          label: qcRef.sd3Low.toString(),
        },
        {
          value: qcRef.sd2Low,
          label: qcRef.sd2Low.toString(),
        },
        {
          value: qcRef.sd1Low,
          label: qcRef.sd1Low.toString(),
        },
        {
          value: qcRef.sd3Low - qcRef.sd,
          label: '',
        },
        {
          value: qcRef.sd3Low - qcRef.sd * 2,
          label: '',
        },
        {
          value: ymin,
          label: '',
        },
      ];
    }
    return ticksValues;
  }

  private getYMinValue(qcRef: QcReference): number {
    return qcRef.sd === 0
      ? qcRef.mean - 1
      : Math.min(qcRef.sd3Low - qcRef.sd * 3);
  }

  private getYMaxValue(qcRef: QcReference): number {
    return qcRef.sd === 0
      ? qcRef.mean + 1
      : Math.max(qcRef.sd3Up + qcRef.sd * 3);
  }

  getUpdatedOptions(data: any, qcRef: QcReference, qcResultFlag: boolean, sliderUpdate: boolean = false) : any {
    this.item = !sliderUpdate ? this.item.qcResults : data;
    const valueYAxes = this.getValueYAxes(data, qcResultFlag);
    const axis = this.getScaleMinMaxValues(qcRef, valueYAxes, qcResultFlag);

    const scatterOptions = {
      scales: {
        x: this.getXAxisConfig(axis),
        y: this.getYAxisConfig(axis, qcRef, qcResultFlag),
      },
      plugins: {
        tooltip: this.getTooltipConfig(qcRef, qcResultFlag),
        legend: this.getLegendConfig(qcResultFlag),
        annotation: this.getAnnotationConfig(axis, qcRef, qcResultFlag),
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    return scatterOptions;
  }

  getValueYAxes(data: any, qcResultFlag: boolean) {
    return !qcResultFlag ? data.map((x: any) => x.y) : [];
  }

  getXAxisConfig(axis: any) {
    return {
      title: {
        text: 'Completed Date',
        display: true,
        align: 'center',
        padding: 1,
        color: '000000E6',
        font: { size: 18, family: 'Siemens Sans', weight: 'bold' },
      },
      type: 'time',
      time: { unit: 'hour', displayFormats: { hour: 'DD/MM/YYYY' } },
      max: axis.xmax.getTime(),
      min: axis.xmin.getTime(),
      afterBuildTicks: (scale: any) => {
        scale.ticks = this.generateXAxisTicks(axis.xmin, axis.xmax);
      },
      ticks: {
        callback: (value: any) => new Date(value).toLocaleDateString(),
        autoSkipPadding: 20,
      },
      adapters: { date: { zone: 'utc' } },
      bounds: 'ticks',
    };
  }

  getYAxisConfig(axis: any, qcRef: QcReference, qcResultFlag: boolean) {
    return {
      title: {
        text: qcResultFlag ? 'QC Result Value' : 'Signal Response (mAU)',
        display: true,
        align: 'center',
        padding: 1,
        color: '000000E6',
        font: { size: 18, family: 'Siemens Sans', weight: 'bold' },
      },
      afterBuildTicks: qcResultFlag
        ? (scale: any) => {
          scale.ticks = this.generateYAxisTicks(qcRef, axis.ymin, axis.ymax);
        }
        : undefined,
      max: qcResultFlag ? (axis.ymax + 2 * qcRef.sd) : null,
      min: qcResultFlag ? (axis.ymin - 2 * qcRef.sd) : null,
      type: 'linear',
      position: 'left',
      ticks: qcResultFlag
        ? {
          callback: (value: any) =>
            this.generateYAxisTicksLabels(value, qcRef, axis.ymax, axis.ymin),
        }
        : {},
      grid: { display: qcResultFlag ? false : true },
    };
  }

  getTooltipConfig(qcRef: QcReference, qcResultFlag: boolean) {
    return {
      titleColor: 'black',
      backgroundColor: 'white',
      bodyColor: 'black',
      callbacks: {
        label: (tooltipItem: any) =>
          (tooltipItem.raw.tooltipLabel || '').split('\n'),
        title: (tooltipItems: any) => {
          if (qcResultFlag && tooltipItems.length > 0) {
            const resVal = tooltipItems[0].raw.y;
            if (resVal >= qcRef.sd2Low && resVal <= qcRef.sd2Up) return '1S';
            if (resVal >= qcRef.sd3Low && resVal <= qcRef.sd3Up) return '2S';
            return '3S';
          }
          return '';
        },
      },
    };
  }

  getLegendConfig(qcResultFlag: boolean) {
    return {
      display: true,
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true,
        font: {
          size: 14,
        },
        padding: 15,
        generateLabels: (chart: Chart) =>
          this.generateYAxisTickLabels(chart, qcResultFlag),
      },
    };
  }

  getAnnotationConfig(axis: any, qcRef: QcReference, qcResultFlag: boolean) {
    if (!qcResultFlag) return {};

    const baseAnnotations =
      qcRef.sd === 0
        ? this.getZeroSdAnnotations(qcRef)
        : this.getSdAnnotations(axis, qcRef);
    const errorAnnotations = this.getErrorAnnotations();

    return { annotations: [...baseAnnotations, ...errorAnnotations] };
  }

  getZeroSdAnnotations(qcRef: QcReference) {
    return [
      {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        yMin: qcRef.mean - 1,
        yMax: qcRef.mean + 1,
        backgroundColor: '#009B000A',
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.mean,
        borderColor: '#009B00',
        borderWidth: 0.5,
        drawTime: 'afterDraw',
      },
    ];
  }

  getSdAnnotations(axis: any, qcRef: QcReference) {
    return [
      {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        yMin: axis.ymax,
        yMax: axis.ymax + 2 * qcRef.sd, //outlier line
        backgroundColor: '#f0f0f0',
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        yMin: axis.ymin,
        yMax: axis.ymin - 2 * qcRef.sd,//outlier line
        backgroundColor: '#f0f0f0',
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        yMin: axis.ymin,
        yMax: qcRef.sd3Low,
        backgroundColor: '#E7001D33',
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        yMin: qcRef.sd3Up,
        yMax: axis.ymax,
        backgroundColor: '#E7001D33',
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        yMin: qcRef.sd2Up,
        yMax: qcRef.sd3Up,
        backgroundColor: '#FCF50033',
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        yMin: qcRef.sd3Low,
        yMax: qcRef.sd2Low,
        backgroundColor: '#FCF50033',
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        yMin: qcRef.sd2Low,
        yMax: qcRef.sd2Up,
        backgroundColor: '#009B000A',
        borderWidth: 0,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.mean,
        borderColor: '#009B00',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd1Low,
        borderColor: '#009B00',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd1Up,
        borderColor: '#009B00',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd2Low,
        borderColor: '#009B00',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd2Up,
        borderColor: '#009B00',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: axis.ymax + qcRef.sd,
        borderColor: '#BFBFBF',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: axis.ymax + 2 * qcRef.sd,
        borderColor: '#787878',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: axis.ymin - qcRef.sd,
        borderColor: '#BFBFBF',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: axis.ymin - 2 * qcRef.sd,
        borderColor: '#787878',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd3Up,
        borderColor: '#FCF500',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd3Low,
        borderColor: '#FCF500',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd3Up + qcRef.sd,
        borderColor: '#E7001D',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd3Up + qcRef.sd + qcRef.sd,
        borderColor: '#E7001D',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: axis.ymax,
        borderColor: '#E7001D',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd3Low - qcRef.sd,
        borderColor: '#E7001D',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: qcRef.sd3Low - qcRef.sd - qcRef.sd,
        borderColor: '#E7001D',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
      {
        type: 'line',
        scaleID: 'y',
        value: axis.ymin,
        borderColor: '#E7001D',
        borderWidth: 0.5,
        drawTime: 'beforeDatasetsDraw',
      },
    ];
  }

  getErrorAnnotations() {
    return (this.item || [])
      .filter((a: any) => a.errFlgs !== '')
      .map((val: any) => ({
        type: 'point',
        backgroundColor: 'transparent',
        borderColor: 'red',
        pointStyle: 'rectRounded',
        radius: 9,
        xValue: moment(val.time.split('Z')[0], true).toDate(),
        yValue: val.plotValue,
        drawTime: 'afterDatasetsDraw',
      }));
  }
}
