import { LoginService } from 'src/app/services/login.service';
import {Component, ElementRef, HostListener} from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { TroubleshootingType } from 'src/app/enums/troubleshooting-type.enum';
import { TroubleshootingInfoModule } from 'src/app/model/access-bar.model';
import { AccessBarService } from 'src/app/services/access-bar.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AccountService } from 'src/app/modules/shared/services/account.service';

/**
 * Access bar accessible across multiple components in your Angular application.
 * Logo,Application Name , Account Details ,Versioning , etc is provided
 * @export
 * @class AccessBarComponent
 */
@Component({
  selector: 'app-access-bar',
  templateUrl: './access-bar.component.html',
  styleUrls: ['./access-bar.component.scss']
})

export class AccessBarComponent {

  currentRoute?: string;
  helpConfirmationPopupVisible: boolean = false;
  feedBackConfirmationPopupVisible: boolean = false;
  tabInfo: TroubleshootingInfoModule = {} as TroubleshootingInfoModule;
  tabText: string = '';
  fileName: string = '';

  constructor(public notificationService: NotificationService, public appConfigService: AppConfigService,
    public router: Router, private questionnaireService: QuestionnaireService,private eRef: ElementRef,
    private accessBarService: AccessBarService, public loginService: LoginService, private route: ActivatedRoute,private accountService:AccountService) {
  }

  /**
   * gets Current route, helps enabling or disabling contents access bar
   *
   * @memberof AccessBarComponent
   */
  ngOnInit() {
    this.fileName = 'Release Notes';
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });
    this.getRouteParam();
    this.getTabDetails();
  }

  getRouteParam() {
    this.route.queryParams.subscribe((queryParams) => {
      if (!queryParams['notificationNo'] || !queryParams['serialNo']) {
        return;
      };
      this.accessBarService.setAccessBarNotification(queryParams);
    })
  }

  /**
   * Check if the current route is troubleshooting
   *
   * @return {*}
   * @memberof AccessBarComponent
   */
  ifCurrentRoute(path: string) {
    return this.currentRoute?.includes(path);
  }
  /**
   * Get the tab details to display in the access bar   *
   * @memberof AccessBarComponent
   */

  closeNotification() {
    if (this.currentRoute?.includes('troubleshooting') && this.questionnaireService.isDirty) {
      this.questionnaireService.setNavigateToHome();
      this.questionnaireService.confirmSaveQuestionnaire();
      return;
    }
    this.notificationService.navigateToNotificationManager();
  }

  getTabDetails() {
    this.accessBarService.getTabDetails().subscribe((tabInfo) => {
      this.tabInfo = tabInfo;
      this.tabText = this.formatTabInfo(tabInfo);
      return this.tabText;
    });
  }

  /**
   * Home button Navigates to Test Selector screen
   * @memberof AccessBarComponent
   */
  onHome() {
    if (this.currentRoute?.includes('troubleshooting') && this.questionnaireService.isDirty) {
      this.questionnaireService.setNavigateToHome();
      this.questionnaireService.confirmSaveQuestionnaire();
      return;
    }
    this.router.navigate(['home']);
  }
  /**
    * Format the tab info to display in the access bar
    * @memberof AccessBarComponent
  */

  formatTabInfo(info: TroubleshootingInfoModule) {
    const text = `${info?.deviceId} - ${info?.troubleshootingType === TroubleshootingType.AssayTroubleshooting ? info?.assay?.name : 'Transfer Arms'}`;
    return text;
  }

  /**
   * Help button open video tutorial
   * @memberof AccessBarComponent
   */
  openVideoModal() {
    this.helpConfirmationPopupVisible = !this.helpConfirmationPopupVisible;
    this.feedBackConfirmationPopupVisible = false;
  }

  /**
   * close video tutorial modal
   * On click of outside of card and icon
   */
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (this.helpConfirmationPopupVisible &&
        !this.eRef.nativeElement.contains(targetElement.closest('.AccessBar-help-modal-container')) &&
        !targetElement.closest('.help-icon')) {
      this.helpConfirmationPopupVisible = false;
    }
    if (this.feedBackConfirmationPopupVisible &&
      !this.eRef.nativeElement.contains(targetElement.closest('.Feedback-modal-container')) &&
      !targetElement.closest('.help-icon')) {
    this.feedBackConfirmationPopupVisible = false;
  }
  }

  logOut() {
    if (this.currentRoute?.includes('troubleshooting') && this.questionnaireService.isDirty) {
      this.questionnaireService.setNavigateToHome();
      this.questionnaireService.confirmSaveQuestionnaire();
    }
    this.accountService.logout();
  }

  downloadReleaseNote() {
    window.open(`${'assets/release-note/' + this.fileName + '.pdf'}`, '_blank');
  }

  onFeedbackClick() {
    this.feedBackConfirmationPopupVisible =  !this.feedBackConfirmationPopupVisible;
    this.helpConfirmationPopupVisible = false;
  }

}
